import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";
 
import axios from 'axios';
import $ from 'jquery';

const parameters = require('../config/Parameters.js');

const SystemManagementLoadWithdrawList = () => {

    localStorage.setItem('mainMenu', "SystemManagementFundTransactions");
    localStorage.setItem('whereAmI', "SystemManagementLoadWithdrawList");
    
    const [load, setLoad] = useState(true);
    // Check if there is JWT
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));
    const [systemLog, setSystemLog] = useState([]);

    const [authCheck, setAuthCheck] = useState('Authenticated');
    const [moneyToWithdraw, setMoneyToWithdraw] = useState('');
    const [moneyToLoad, setMoneyToLoad] = useState('');
    const [loadBankId, setLoadBankId] = useState('');
    const [withdrawBankId, setWithdrawBankId] = useState('');
    const [result1, setResult1] = useState('');
    const [result2, setResult2] = useState('');

    const [adminList, setAdminList] = useState('');
    const [adminId, setAdminId] = useState('');
     
    const [result, setResult] = useState("");
 
    const [creditCardIndexNumber, setCreditCardIndexNumber] = useState(0);
    const [bankAccountIndexNumber, setBankAccountIndexNumber] = useState(0);
 
    const [adminWalletFinalStatus, setAdminWalletFinalStatus] = useState();
 
    const getAdminWalletFinalStatus = event => {
      axios.get(
        parameters.ENDPOINT_URL+'/admin/get-admin-wallet-final-status', {
          headers: {
              'accept': 'application/json',
              'content-type': 'application/x-www-form-urlencoded',
              'token': jwt
          }
      })
      .then(res => { 
          console.log(res.data.data[0].wallet_money);
          setAdminWalletFinalStatus(res.data.data[0].wallet_money);
      })
      .catch(err => {
        console.log(err);
      })
       
    }




    useEffect(() => {

      if (localStorage.getItem('jwt')) {
        setJwt(localStorage.getItem('jwt'));
      } else {
          setAuthCheck('401');
      }
 
    setLoad(false);
    getAdminWalletFinalStatus();
    getList();

	}, []);
 
 

  const getList = event => {
    // Banks
    axios
    .get(parameters.ENDPOINT_URL+"/admin/get-admin-list", {
      headers: {
        accept: "application/json",
        "content-type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
    .then(res => {
      setAdminList(res.data.data);
      console.log(res.data.data)
    })
    .catch(err => {
      setResult(
        <div className="alert alert-danger" role="alert">
          Temporary Error Occured
        </div>
      );
    });
     
      }

const handleLoadSubmit = event => {
    event.preventDefault();

    
    axios({
        method: "post",
        url: parameters.ENDPOINT_URL+"/admin/get-admin-wallet-details",
        params: {
          adminId:adminId
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: jwt
        }
      }).then(res => {
        console.log( JSON.stringify(res.data.resultDetail) )
        setResult2(res.data.resultDetail); 
        getAdminWalletFinalStatus();
    })
    .catch(err => {
      console.log( JSON.stringify(err) )
      setResult2(
        <div>
        <div>Error occured</div>
      </div>
      )
    });


}

const handleWithdrawSubmit = event => {

    event.preventDefault();
    
    axios({
        method: "post",
        url: parameters.ENDPOINT_URL+"/admin/send-system-to-admin",
        params: {
          amount:moneyToWithdraw
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: jwt
        }
      }).then(res => {
        console.log( JSON.stringify(res) )
        setResult2(
          <div>
          <div>&nbsp;</div>
          <div class="alert alert-info" role="alert">
              Transfer is successful.
          </div>
        </div>
        );
        getAdminWalletFinalStatus();
    })
    .catch(err => {
      console.log( JSON.stringify(err) )
      setResult2(
        <div>
        <div>&nbsp;</div>
        <div class="alert alert-danger" role="alert">
            Transfer is failed.
        </div>
      </div>
      )
    });
} 


  // Redirect if there is token
  if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
  }

    return (
        
<LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>System Management - Debit Credit</title>
            <meta name="description" content="Main Page" />
            </Helmet>

            <div id="wrapper">
                    <Menu />
                    
                    <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
             
        <div className="spacer-25"></div>
   

        <div class="container-fluid"> 
            <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Transactions</h6>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <form className="user" onSubmit={handleLoadSubmit}>
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="30%">Admins</td>
                      <td width="30%"> 
                      <select className="form-control" onChange={(e) => { setAdminId(e.target.value) }}> 
                            <option value='0'>-- Please Select Admin --</option> 
                            {adminList ? (
                            adminList.map((adminListValue, index) => (
                                <option value={adminListValue.id}>{adminListValue.name} </option>
                                ))
                                ) : (
                                 ''
                                )}
                      </select> 
                      </td>
                      <td width="30%"><button type="submit" className="btn btn-primary full-width">List</button></td>
                    </tr> 
                  </tbody>
                </table>
                </form>
            </div></div></div>
          </div>
 
 



          <div class="container-fluid"> 
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Wallet Details</h6>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-bordered table-sortable" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th>Transaction Id</th>
                      <th>Name</th>
                      <th>Date</th> 
                      <th>Reason Code</th> 
                      <th>Amount</th>
                      <th>Detail</th> 
                    </tr>
                  </thead>
                  <tfoot>
                        <tr>
                            <th>Transaction Id</th>
                            <th>Name</th>
                            <th>Date</th> 
                            <th>Amount</th> 
                            <th>Reason Code</th> 
                            <th>Detail</th>
                        </tr>
                  </tfoot>
                  <tbody>
                    
                    {result2 ? (
                            result2.map((result2Value, index) => (
                                <tr> 

                                <td>{result2Value.transaction_id}</td> 
                                <td>{result2Value.name} {result2Value.surname}</td> 
                                <td>{result2Value.created_at}</td> 
                                <td>{result2Value.amount}</td>
                                <td>{result2Value.reason_code}</td> 
                                <td>{result2Value.origin_type} to {result2Value.target_type} ({result2Value.transfer_type})</td> 
                                </tr>
                            ))) : (
                                <tr><td></td></tr>
                            )
                        }

                  </tbody>
                </table> 
              </div>
            </div>
          </div>
          </div>
          






                    </div>
                    <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Mahalome 2020</span>
                        </div>
                    </div>
                    </footer>
            </div>
            </div>
  

 </LoadingOverlay>
 
    );
};

export default SystemManagementLoadWithdrawList;
