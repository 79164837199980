import React, {useState, useEffect} from 'react';
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

const parameters = require('../config/Parameters.js');

const Logout = () => {

  // Loading Layer
  const [load, setLoad] = useState(true);

  // Form Variables
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Result Messages After Service Call
  const [result, setResult] = useState('');

  useEffect(() => {
    setLoad(false);

   // set value
   setEmail(email);
   setPassword(password);

   axios.post(parameters.ENDPOINT_URL+'/admin/logout',{
       email: email,
       password: password
   })
   .then(res => {
       localStorage.setItem('jwt', '');
       setResult( 
           <div className="alert alert-success text-center">
               Successfully Loged Out!
           </div>
       );
   })
   .catch(err => {
       setResult(
           <div>
             <div>&nbsp;</div>
             <div class="alert alert-danger text-center">
                Successfully Loged Out!
             </div>
           </div>
       );
       localStorage.setItem('jwt', '');
   })

  }, []);

	return (

        <LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>Logout</title>
            <meta name="description" content="Login" />
            </Helmet>

<div className="container">
<div className="row">
  <div className="col-xl-12 col-lg-12 col-md-12">
    <div className="card o-hidden border-0 shadow-lg">
 
        
          
            <div className="spacer-60"></div>
            <div>
              <p className="a text-center">{result}</p>
            </div>
            <div className="spacer-60"></div>

      
 
    </div>
  </div>
</div>
</div>
</LoadingOverlay>

);

}

export default Logout;


