import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";
import axios from 'axios';

const parameters = require('../config/Parameters.js');

const Main = () => {
 
    localStorage.setItem('mainMenu', "MainDashboard");
    localStorage.setItem('whereAmI', "MainDashboard");

    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

	const [load, setLoad] = useState("q");
    const [totalStatistics, setTotalStatistics] = useState();
    const [result, setResult] = useState('');
    const [redirect, setRedirect] = useState(0);
 
    const [authCheck, setAuthCheck] = useState('Authenticated');

    const [totalCommision, setTotalCommision] = useState('');
    const [totalAmounthInUserWallets, setTotalAmounthInUserWallets] = useState('');
    const [totalBonusInSystem, setTotalBonusInSystem] = useState('');
    const [totalSytemWallet, setTotalSytemWallet] = useState('');

    const [topUserToBank, setTopUserToBank] = useState([]);
    const [topBankToUser, setTopBankToUser] = useState([]);
    const [topReceivers, setTopReceivers] = useState([]);
    const [topSenders, setTopSenders] = useState([]);

    useEffect(() => {

        setLoad(false);

        if (localStorage.getItem('jwt')) {
            setJwt(localStorage.getItem('jwt'));
        } else {
            setAuthCheck('401');
        }

        // axios.get(
        //     parameters.ENDPOINT_URL+'/admin/auth/check-session', {
        //     headers: {
        //         'accept': 'application/json',
        //         'content-type': 'application/x-www-form-urlencoded',
        //         'token': jwt
        //     }
        //     })
        //     .then(res => { 
        //     setAuthCheck(res.data.result);
        //     console.log("**"+res.data.result);
        //     })
        //     .catch(err => {
        //       setAuthCheck('401');
        //     }) 
 
       axios.get(
        parameters.ENDPOINT_URL+'/admin/dashboard-summary', {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'token': jwt
        }
    })
    .then(res => {  
        
console.log(res.data.data);

        //console.log("wallet_money"+res.data.data.Result2[0].wallet_money);
        //console.log("wallet_commission"+res.data.data.Result2[0].wallet_commission);

        setTotalAmounthInUserWallets(res.data.data.Result1[0].total_amount_in_user_wallets);
        setTotalBonusInSystem(res.data.data.Result1[0].total_bonus_in_system);
        setTotalSytemWallet(res.data.data.Result1[0].total_system_wallet);
        setTotalCommision(res.data.data.Result1[0].total_commision);
    })
    .catch(err => {
        setResult(
            <div class="alert alert-danger" role="alert">
                Temporary System Error!
            </div>
        );
    })

    axios.get(
        parameters.ENDPOINT_URL+'/admin/dashboard-summary-list', {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/x-www-form-urlencoded',
            'token': jwt
        }
    })
    .then(res => {  
        console.log(res.data.data.Result1);
        console.log(res.data.data.Result2);
        console.log(res.data.data.Result3);
        console.log(res.data.data.Result4);

        setTopBankToUser(res.data.data.Result1);
        setTopUserToBank(res.data.data.Result2);
        setTopReceivers(res.data.data.Result3);
        setTopSenders(res.data.data.Result4);  
        
    })
    .catch(err => {
        setResult(
            <div class="alert alert-danger" role="alert">
                Temporary System Error!
            </div>
        );
    })
   
	}, []);
    
  // Redirect if there is token
  if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
  }

    return (
        
<LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>Main Page</title>
            <meta name="description" content="Main Page" />
            </Helmet>

            <div id="wrapper">
                    <Menu />
                    
                    <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                    <div class="container-fluid">
                        <h1 className="h3 mb-4 text-gray-800">Dashboard</h1>
  
                        <div className="row">                        
                        <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Total Amount in User Wallets</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">$ {totalAmounthInUserWallets} </div>
                                </div>
                                <div className="col-auto">
                                <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Total Bonus in System</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">$ {totalBonusInSystem} </div>
                                </div>
                                <div className="col-auto">
                                <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-info text-uppercase mb-1">System Wallet</div>
                                <div className="row no-gutters align-items-center">
                                    <div className="col-auto">
                                    <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">$ {totalSytemWallet}</div>
                                    </div>
                                </div>
                                </div>
                                <div className="col-auto">
                                <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">System Profit</div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">$ {totalCommision}</div>
                                </div>
                                <div className="col-auto">
                                <i className="fas fa-comments fa-2x text-gray-300"></i>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>  

<div className="row">    
          <div className="col-lg-6 mb-4">
          <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Top Bank to User Transactions</h6>
                </div>
                <div className="card-body">                        
                    {topBankToUser ? (
                        topBankToUser.map((topBankToUserValue, index) => (
                    <div>
                    <h4 className="small font-weight-bold">{topBankToUserValue.myname} ({topBankToUserValue.ratio}%) <span className="float-right">{topBankToUserValue.amount} $ </span></h4>
                    <div className="progress mb-4">
                        <div className="progress-bar bg-warning" role="progressbar" style={{width: `${topBankToUserValue.ratio}%`}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>                                                       
                    </div>
                    </div>
                        ))
                    ) : (
                        <div></div>
                    )}
                </div>
          </div>
          </div>

          <div className="col-lg-6 mb-4">
          <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Top User to Bank Transactions</h6>
                </div>
                
                <div className="card-body">                        

                    {topUserToBank ? (
                        topUserToBank.map((topUserToBankValue, index) => (
                    <div>
                    <h4 className="small font-weight-bold">{topUserToBankValue.myname} ({topUserToBankValue.ratio}%) <span className="float-right">{topUserToBankValue.amount} $ </span></h4>
                    <div className="progress mb-4">
                        <div className="progress-bar bg-danger" role="progressbar" style={{width: `${topUserToBankValue.ratio}%`}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    </div>
                        ))
                    ) : (
                        <div></div>
                    )}
                </div>

                {/* <div className="card-body">
                <h4 className="small font-weight-bold">Test User <span className="float-right">20%</span></h4>
                <div className="progress mb-4">
                    <div className="progress-bar bg-danger" role="progressbar" style={{width: '20%'}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <h4 className="small font-weight-bold">Test User <span className="float-right">40%</span></h4>
                <div className="progress mb-4">
                    <div className="progress-bar bg-warning" role="progressbar" style={{width: '20%'}} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <h4 className="small font-weight-bold">Test User <span className="float-right">60%</span></h4>
                <div className="progress mb-4">
                    <div className="progress-bar" role="progressbar" style={{width: '20%'}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <h4 className="small font-weight-bold">Test User <span className="float-right">80%</span></h4>
                <div className="progress mb-4">
                    <div className="progress-bar bg-info" role="progressbar" style={{width: '20%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <h4 className="small font-weight-bold">Test User <span className="float-right">Complete!</span></h4>
                <div className="progress">
                    <div className="progress-bar bg-success" role="progressbar" style={{width: '20%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div> */}

          </div>
          </div>
</div>

<div className="row">    
          <div className="col-lg-6 mb-4">
          <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Top Recievers ( User to User Transactions )</h6>
                </div>
                <div className="card-body">                        
                    {topReceivers ? (
                        topReceivers.map((topReceiversValue, index) => (
                    <div>
                    <h4 className="small font-weight-bold">{topReceiversValue.myname} ({topReceiversValue.ratio}%) <span className="float-right">{topReceiversValue.amount} $ </span></h4>
                    <div className="progress mb-4">
                        <div className="progress-bar bg-info" role="progressbar" style={{width: `${topReceiversValue.ratio}%`}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    </div>
                        ))
                    ) : (
                        <div></div>
                    )}
                </div>
          </div>
          </div>

          <div className="col-lg-6 mb-4">
          <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Top Senders ( User to User Transactions )</h6>
                </div>
                <div className="card-body">                        
                    {topSenders ? (
                        topSenders.map((topSendersValue, index) => (
                    <div>
                    <h4 className="small font-weight-bold"> {topSendersValue.myname} ({topSendersValue.ratio}%) <span className="float-right">{topSendersValue.amount} $ </span></h4>
                    <div className="progress mb-4">
                    <div className="progress-bar bg-success" role="progressbar"  style={{width: `${topSendersValue.ratio}%`}} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">    
                    </div>
                    </div>
                    </div>
                        ))
                    ) : (
                        <div></div>
                    )}
                </div>
          </div>
          </div>
</div>
 
                    </div>
                    </div>
                    <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Mahalome 2020</span>
                        </div>
                    </div>
                    </footer>
 
            </div>
            </div>
 
 </LoadingOverlay>
 
    );
};

export default Main;
