import React, {useState, useEffect} from 'react';
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

const parameters = require('../config/Parameters.js');

const Login = () => {

  // Page Session Check and/or Redirection
  const [redirect, setRedirect] = useState(0);
  
  // Check if there is JWT
  const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

  // Loading Layer
  const [load, setLoad] = useState(true);

  // Form Variables
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Result Messages After Service Call
  const [result, setResult] = useState('');

  const [authCheck, setAuthCheck] = useState('401');

  useEffect(() => {
  setLoad(false);

  axios.get(
    parameters.ENDPOINT_URL+'/admin/auth/check-session', {
      headers: {
          'accept': 'application/json',
          'content-type': 'application/x-www-form-urlencoded',
          'token': jwt
      }
  })
  .then(res => { 
      setAuthCheck(res.data.result);
      console.log("**"+res.data.result);
  })
  .catch(err => {
  }) 

  }, []);

  const handleSubmit = event => {

    event.preventDefault();

    // set value
    setEmail(email);
    setPassword(password);

    console.log('ff');
    
    axios.post(parameters.ENDPOINT_URL+'/admin/login',{
        email: email,
        password: password
    })
    .then(res => {

        localStorage.setItem('jwt', res.data.data.jwt);
        localStorage.setItem('email', res.data.data.email);
        localStorage.setItem('name', res.data.data.name);
        localStorage.setItem('surname', res.data.data.surname);
        setAuthCheck("Authenticated");
    })
    .catch(err => {
        console.log(err.message);
        setResult(
            <div>
              <div>&nbsp;</div>
              <div class="alert alert-danger" role="alert">
                  Email or password is incorrect
              </div>
            </div>
        );
    });

}

  // Redirect if there is token
  if(authCheck==="Authenticated" ){
    return <Redirect to="/" />
  }

	return (

        <LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>Login</title>
            <meta name="description" content="Login" />
            </Helmet>

<div className="container">
<div className="row justify-content-center">
  <div className="col-xl-10 col-lg-12 col-md-9">
    <div className="card o-hidden border-0 shadow-lg my-5">
      <div className="card-body p-0">
        <div className="row">
        <div className="spacer-20"></div>
          <div className="col-lg-6 d-none d-lg-block text-center"><img src="/img/Logo_Female_for_admin.png" width="70%" /></div>
          <div className="col-lg-6">
            <div className="p-5">
              <div className="text-center">
                <h1 className="h4 text-gray-900 mb-4">Welcome Mahalome!</h1>
              </div>
              <form className="user" onSubmit={handleSubmit}>
                <div className="form-group"> 
                  <input type="email" value={email} required onChange={(e) => { setEmail(e.target.value) }} className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." />
                </div>
                <div className="form-group">
                  <input type="password" value={password} required onChange={(e) => { setPassword(e.target.value) }} className="form-control form-control-user" id="exampleInputPassword" placeholder="Password" />
                </div>
                <div className="form-group">
                  <div className="custom-control custom-checkbox small">
                    <input type="checkbox" className="custom-control-input" id="customCheck" />
                  </div>
                </div>
                <input type="submit" value="Login" className="btn btn-primary btn-user btn-block" />
                {result}
              </form>
              <hr />
              {/* <div className="text-center">
                <a className="small" href="forgot-password">Forgot Password?</a>
              </div>
              <div className="text-center">
                <a className="small" href="register">Create an Account!</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</LoadingOverlay>

);

}

export default Login;


