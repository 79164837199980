import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';

const NotFound = () => {

	const [load, setLoad] = useState(true);

    useEffect(() => {

		setLoad(false);
		
	}, []);
	
    return (
        <div>
            <LoadingOverlay active={load} spinner>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Page Not Found - 404</title>
                    <meta name="description" content="Page Not Found" />
                </Helmet>
                <div className="bg-header-sub">
                    <div className="bg-header-sub-content text-center">
                        <h1>404</h1>
                        <p>PAGE NOT FOUND!</p>
                    </div>
                    <div className="bg-header-search">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul className="breadcrumb-list">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/places" className="breadcrumb-list-end">404</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				<div className="container">
					<div className="row">
						<div className="col-lg-8 mx-auto text-center">
							<div className="spacer-120"></div>
							<h2><b>Ooops, This Page Could Not Be Found!</b></h2>
							<p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable. Click here to return to <Link to="/" className="link"><b>home</b></Link>.</p>
							<div className="spacer-120"></div>
						</div>
					</div>
				</div>
                <div className="bg-pink">
                    <div className="container">
                        <div className="row">
                            <div className="spacer-60"></div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="features">
                                    <i className="fa fa-globe" aria-hidden="true"></i>
                                    <div className="features-count ml-4">100 +</div>
                                    <div className="features-text ml-4">New Places Every Day</div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="features">
                                    <i className="fa fa-smile-o" aria-hidden="true"></i>
                                    <div className="features-count ml-4">9500 +</div>
                                    <div className="features-text ml-4">Happy Costumers</div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="features">
                                    <i className="fa fa-comment-o" aria-hidden="true"></i>
                                    <div className="features-count ml-4">25000 +</div>
                                    <div className="features-text ml-4">Costumers Review</div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="features">
                                    <i className="fa fa-location-arrow" aria-hidden="true"></i>
                                    <div className="features-count ml-4">5500 +</div>
                                    <div className="features-text ml-4">Unique Places and Events</div>
                                </div>
                            </div>
                            <div className="spacer-60"></div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </div>
    );
};

export default NotFound;
