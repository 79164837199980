import React, {useState,useEffect} from 'react';
import { NavLink } from 'react-router-dom';
 
const Menu = () => {

	const [activeClass, setActiveClass] = useState('');
	const [mainMenu, setMainMenu] = useState();

    useEffect(() => {

	}, []);

	return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
		  <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
			<div className="sidebar-brand-icon">
			<img src="/img/LogoMahalome.png" width="40" />
			</div>
			<div className="sidebar-brand-text mx-3">Mahalome!</div>
		  </a>

		  <hr className="sidebar-divider my-0" />
 
		  <li className={localStorage.getItem('mainMenu') === 'MainDashboard' ? 'nav-item active' : 'nav-item' }>
			<a className="nav-link" href="/">
			  <i className="fas fa-fw fa-tachometer-alt"></i>
			  <span>Dashboard</span></a>
		  </li>

		  <hr className="sidebar-divider" />

		  <div className="sidebar-heading">
			User Management
		  </div>
 
		  <li className={localStorage.getItem('mainMenu') === 'UserManagementEndUsers' ? 'nav-item active' : 'nav-item' }> 
			<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#UserManagement" aria-expanded="true" aria-controls="UserManagement">
			  <i className="fas fa-fw fa-cog"></i>
			  <span>End Users</span>
			</a>
			<div id="UserManagement" className={localStorage.getItem('mainMenu') === 'UserManagementEndUsers' ? 'collapse show' : 'collapse ' } aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			  <div className="bg-white py-2 collapse-inner rounded">
				<h6 className="collapse-header">End Users</h6>
				<a className={localStorage.getItem('whereAmI') === 'UserManagementListUser' ? 'collapse-item active' : 'collapse-item ' } href="/user-management-list-user">List User</a> 
			  </div>
			</div>
		  </li>

		  <li className={localStorage.getItem('mainMenu') === 'UserManagementSecurityOperations' ? 'nav-item active' : 'nav-item' }> 
			<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#SecurityOperations" aria-expanded="true" aria-controls="SecurityOperations">
			  <i className="fas fa-fw fa-cog"></i>
			  <span>Security Operations:</span>
			</a>
			<div id="SecurityOperations" className={localStorage.getItem('mainMenu') === 'UserManagementSecurityOperations' ? 'collapse show' : 'collapse ' } aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			  <div className="bg-white py-2 collapse-inner rounded">
				<h6 className="collapse-header">Security Operations:</h6>
				<a className={localStorage.getItem('whereAmI') === 'UserManagementTransactionLogging' ? 'collapse-item active' : 'collapse-item ' } href="/user-management-transaction-logging">Transaction Logging</a> 
			  </div>
			</div>
		  </li>

		  <li className={localStorage.getItem('mainMenu') === 'UserManagementFundTransactions' ? 'nav-item active' : 'nav-item' }> 
			<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#UserManagementDebitCredit" aria-expanded="true" aria-controls="UserManagementDebitCredit">
			  <i className="fas fa-fw fa-cog"></i>
			  <span>Fund Transactions:</span>
			</a>
			<div id="UserManagementDebitCredit" className={localStorage.getItem('mainMenu') === 'UserManagementFundTransactions' ? 'collapse show' : 'collapse ' } aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			  <div className="bg-white py-2 collapse-inner rounded">
				<h6 className="collapse-header">Fund Transactions:</h6>
				<a className={localStorage.getItem('whereAmI') === 'UserManagementDebitCredit' ? 'collapse-item active' : 'collapse-item ' } href="/user-management-debit-credit">Debit/Credit</a> 
			  </div>
			</div>
		  </li>
	
		  <hr className="sidebar-divider" />
	
		  <div className="sidebar-heading">
		  Admin Management
		  </div>

		<li className={localStorage.getItem('mainMenu') === 'SystemManagementAdminUsers' ? 'nav-item active' : 'nav-item' }> 
			<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#SystemManagementAdminList" aria-expanded="true" aria-controls="SystemManagementAdminList">
			  <i className="fas fa-fw fa-cog"></i>
			  <span>Admin Users:</span>
			</a>
			<div id="SystemManagementAdminList" className={localStorage.getItem('mainMenu') === 'SystemManagementAdminUsers' ? 'collapse show' : 'collapse ' } aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			  <div className="bg-white py-2 collapse-inner rounded">
				<h6 className="collapse-header">Admin Users:</h6>
				<a className={localStorage.getItem('whereAmI') === 'SystemManagementAdminList' ? 'collapse-item active' : 'collapse-item ' } href="/system-management-admin-list">List</a> 
			  </div>
			</div>
		  </li>
	
		  <li className={localStorage.getItem('mainMenu') === 'SystemManagementSecurityOperations' ? 'nav-item active' : 'nav-item' }> 
			<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#SystemManagementIPRestriction" aria-expanded="true" aria-controls="SystemManagementIPRestriction">
			  <i className="fas fa-fw fa-cog"></i>
			  <span>Security Operations:</span>
			</a>
			<div id="SystemManagementIPRestriction" className={localStorage.getItem('mainMenu') === 'SystemManagementSecurityOperations' ? 'collapse show' : 'collapse ' } aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			  <div className="bg-white py-2 collapse-inner rounded">
				<h6 className="collapse-header">Security Operations:</h6>

				{/* <a className={localStorage.getItem('whereAmI') === 'SystemManagementIPRestriction' ? 'collapse-item active' : 'collapse-item ' } href="/system-management-ip-restriction">IP Restriction</a>  */}
				
				<a className={localStorage.getItem('whereAmI') === 'SystemManagementTransactionTracking' ? 'collapse-item active' : 'collapse-item ' } href="/system-management-transaction-logging">Transaction Tracking</a> 

			  </div>
			</div>
		  </li>

	<li className={localStorage.getItem('mainMenu') === 'SystemManagementFundTransactions' ? 'nav-item active' : 'nav-item' }> 
			<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#SystemManagementDebitCredit" aria-expanded="true" aria-controls="SystemManagementDebitCredit">
			  <i className="fas fa-fw fa-cog"></i>
			  <span>Fund Transactions:</span>
			</a>
			<div id="SystemManagementDebitCredit" className={localStorage.getItem('mainMenu') === 'SystemManagementFundTransactions' ? 'collapse show' : 'collapse ' } aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			  <div className="bg-white py-2 collapse-inner rounded">
				<h6 className="collapse-header">Fund Transactions</h6>
				<a className={localStorage.getItem('whereAmI') === 'SystemManagementDebitCredit' ? 'collapse-item active' : 'collapse-item ' } href="/system-management-debit-credit">Commission List</a> 
				<a className={localStorage.getItem('whereAmI') === 'SystemManagementLoadWithdraw' ? 'collapse-item active' : 'collapse-item ' } href="/system-management-load-withdraw">Load/Withdraw</a> 
				<a className={localStorage.getItem('whereAmI') === 'SystemManagementLoadWithdrawList' ? 'collapse-item active' : 'collapse-item ' } href="/system-management-load-withdraw-list">Load/Withdraw List</a> 
				

			  </div>
			</div>
		  </li>

		  <hr className="sidebar-divider" />
	
		  <div className="sidebar-heading">
		  System Settings
		  </div>

		  <li className={localStorage.getItem('mainMenu') === 'SettingsParameters' ? 'nav-item active' : 'nav-item' }> 
			<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#SystemManagementParamters" aria-expanded="true" aria-controls="SystemManagementParamters">
			  <i className="fas fa-fw fa-cog"></i>
			  <span>Paramters:</span>
			</a>
			<div id="SystemManagementParamters" className={localStorage.getItem('mainMenu') === 'SettingsParameters' ? 'collapse show' : 'collapse ' } aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			  <div className="bg-white py-2 collapse-inner rounded">
				<h6 className="collapse-header">Paramters:</h6>
				<a className={localStorage.getItem('whereAmI') === 'SettingsEndUserParameters' ? 'collapse-item active' : 'collapse-item ' } href="/settings-end-user-parameters">End User Parameters</a>
				<a className={localStorage.getItem('whereAmI') === 'SettingsAdminParameters' ? 'collapse-item active' : 'collapse-item ' } href="/settings-admin-parameters">Admin Parameters</a>
				<a className={localStorage.getItem('whereAmI') === 'SettingsSystemParameters' ? 'collapse-item active' : 'collapse-item ' } href="/settings-system-parameters">System Parameters</a>
				<a className={localStorage.getItem('whereAmI') === 'SettingsMailParameters' ? 'collapse-item active' : 'collapse-item ' } href="/settings-mail-parameters">Mail Parameters</a>
				<a className={localStorage.getItem('whereAmI') === 'SettingsMessageParameters' ? 'collapse-item active' : 'collapse-item ' } href="/settings-message-parameters">Message Parameters</a>
			  </div>
			</div>
		  </li>

		  <li className={localStorage.getItem('mainMenu') === 'SystemSettingsStaticPages' ? 'nav-item active' : 'nav-item' }> 
			<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#SystemSettingsStaticPages" aria-expanded="true" aria-controls="SystemSettingsStaticPages">
			  <i className="fas fa-fw fa-cog"></i>
			  <span>Static Pages:</span>
			</a>
			<div id="SystemSettingsStaticPages" className={localStorage.getItem('mainMenu') === 'SystemSettingsStaticPages' ? 'collapse show' : 'collapse ' } aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			  <div className="bg-white py-2 collapse-inner rounded">
				<h6 className="collapse-header">Paramters:</h6>
				<a className={localStorage.getItem('whereAmI') === 'SystemSettingsStaticPagesAbout' ? 'collapse-item active' : 'collapse-item ' } href="/system-settings-static-pages-about">About</a>
				<a className={localStorage.getItem('whereAmI') === 'SystemSettingsStaticPagesPrivacy' ? 'collapse-item active' : 'collapse-item ' } href="/system-settings-static-pages-privacy">Privacy</a> 
				<a className={localStorage.getItem('whereAmI') === 'SystemSettingsStaticPagesHelp' ? 'collapse-item active' : 'collapse-item ' } href="/system-settings-static-pages-help">Help</a>
				<a className={localStorage.getItem('whereAmI') === 'SystemSettingsStaticPagesMail' ? 'collapse-item active' : 'collapse-item ' } href="/system-settings-static-pages-mail">Mail Content</a>
			  </div>
			</div>
		  </li>

		  <hr className="sidebar-divider" />
	
 {/* <div className="sidebar-heading">
		  Marketing
		  </div> */}
{/* 
	<li className={localStorage.getItem('mainMenu') === 'MarketingCampaigns' ? 'nav-item active' : 'nav-item' }> 
			<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#MarketingCampaigns" aria-expanded="true" aria-controls="MarketingCampaigns">
			  <i className="fas fa-fw fa-cog"></i>
			  <span>Campaigns:</span>
			</a>
			<div id="MarketingCampaigns" className={localStorage.getItem('mainMenu') === 'MarketingCampaigns' ? 'collapse show' : 'collapse ' } aria-labelledby="headingTwo" data-parent="#accordionSidebar">
			  <div className="bg-white py-2 collapse-inner rounded">
				<h6 className="collapse-header">Campaigns:</h6>
				<a className={localStorage.getItem('whereAmI') === 'MarketingListCampaign' ? 'collapse-item active' : 'collapse-item ' } href="marketing-list-campaign">Campaign</a>
				 
			  </div>
			</div>
		  </li>
	  <hr className="sidebar-divider d-none d-md-block" /> 
	  */}
		 

		  <div className="text-center d-none d-md-inline">
			<button className="rounded-circle border-0" id="sidebarToggle"></button>
		  </div>
		</ul>

);

}

export default Menu;

