import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";
 
const parameters = require('../config/Parameters.js');

const ReportsSales = () => {

    localStorage.setItem('mainMenu', "MarketingReports");
    localStorage.setItem('whereAmI', "MarketingSales");

	const [load, setLoad] = useState(true);

    useEffect(() => {

		setLoad(false);
		
	}, []);
	
    return (
        
<LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>Reports - Sales</title>
            <meta name="description" content="Main Page" />
            </Helmet>

            <div id="wrapper">
                    <Menu />
                    
                    <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                    
                    <div class="container-fluid">
                        <h1 class="h3 mb-4 text-gray-800">Reports - Sales</h1>
                    </div>
                    
                    </div>

                    <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Mahalome 2020</span>
                        </div>
                    </div>
                    </footer>
 
            </div>
            </div>
 
 </LoadingOverlay>
 
    );
};

export default ReportsSales;
