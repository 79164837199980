import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Main from './common/Main';
import Login from './auth/Login';
import Logout from './auth/Logout';
import Register from './auth/Register';
import ForgotPassword from './auth/ForgotPassword';
import NotFound from './error/404';

import Profile from './common/Profile';
import UserManagementDebitCredit from './common/UserManagementDebitCredit';
import UserManagementListUser from './common/UserManagementListUser';
import UserManagementUserEdit from './common/UserManagementUserEdit';
import UserManagementTransactionLogging from './common/UserManagementTransactionLogging';

import SystemManagementAdminList from './common/SystemManagementAdminList';
import SystemManagementAdminEdit from './common/SystemManagementAdminEdit';
// import SystemManagementIpRestriction from './common/SystemManagementIpRestriction';
import SystemManagementTransactionLogging from './common/SystemManagementTransactionLogging';
import SystemManagementDebitCredit from './common/SystemManagementDebitCredit';
import SystemManagementLoadWithdraw from './common/SystemManagementLoadWithdraw';
import SystemManagementLoadWithdrawList from './common/SystemManagementLoadWithdrawList';

import SettingsEndUserParameters from './common/SettingsEndUserParameters';
import SettingsAdminParameters from './common/SettingsAdminParameters';
import SettingsSystemParameters from './common/SettingsSystemParameters';
import SettingsMailParameters from './common/SettingsMailParameters';
import SettingsMessageParameters from './common/SettingsMessageParameters';

import MarketingListCampaign from './common/MarketingListCampaign';
import MarketingCreateCampaign from './common/MarketingCreateCampaign';

import ReportsTips from './common/ReportsTips';
import ReportsSales from './common/ReportsSales';

import SystemSettingsStaticPagesAbout from './common/SystemSettingsStaticPagesAbout';
import SystemSettingsStaticPrivacy from './common/SystemSettingsStaticPrivacy';
import SystemSettingsStaticLegal from './common/SystemSettingsStaticLegal';
import SystemSettingsStaticHelp from './common/SystemSettingsStaticHelp';
import SystemSettingsStaticPagesMail from './common/SystemSettingsStaticPagesMail';



const Routes = () => (
  <Switch>
    <Route exact path="/" component={Main} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/register" component={Register} />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    
    <Route path="/profile" component={Profile} />
    <Route path="/user-management-list-user/:pageNo?" component={UserManagementListUser} />
    <Route path="/user-management-user-edit/:userId" component={UserManagementUserEdit} />
    
    <Route path="/user-management-transaction-logging/:uId" component={UserManagementTransactionLogging} />
    <Route path="/user-management-transaction-logging/" component={UserManagementTransactionLogging} />

    <Route path="/user-management-debit-credit/:uId?" component={UserManagementDebitCredit} />
    <Route path="/system-management-transaction-logging/:uId?" component={SystemManagementTransactionLogging} />
    <Route path="/system-management-debit-credit/:uId?" component={SystemManagementDebitCredit} />

    <Route path="/system-management-admin-list" component={SystemManagementAdminList} />
    <Route path="/system-management-admin-edit/:adminId" component={SystemManagementAdminEdit} />

    {/* <Route path="/system-management-ip-restriction" component={SystemManagementIpRestriction} /> */}
    <Route path="/system-management-debit-credit" component={SystemManagementDebitCredit} />
    <Route path="/system-management-load-withdraw" component={SystemManagementLoadWithdraw} />
    <Route path="/system-management-load-withdraw-list" component={SystemManagementLoadWithdrawList} />
 
    <Route path="/system-settings-static-pages-about" component={SystemSettingsStaticPagesAbout} />
    <Route path="/system-settings-static-pages-mail" component={SystemSettingsStaticPagesMail} />
    
    <Route path="/system-settings-static-pages-privacy" component={SystemSettingsStaticPrivacy} />
    <Route path="/system-settings-static-pages-legal" component={SystemSettingsStaticLegal} />
    <Route path="/system-settings-static-pages-help" component={SystemSettingsStaticHelp} />
    <Route path="/settings-end-user-parameters" component={SettingsEndUserParameters} />
    <Route path="/settings-admin-parameters" component={SettingsAdminParameters} />
    <Route path="/settings-system-parameters" component={SettingsSystemParameters} />
    <Route path="/settings-mail-parameters" component={SettingsMailParameters} />
    <Route path="/settings-message-parameters" component={SettingsMessageParameters} />
    <Route path="/marketing-list-campaign" component={MarketingListCampaign} />
    <Route path="/marketing-create-campaign" component={MarketingCreateCampaign} />
    <Route path="/reports-tips" component={ReportsTips} />
    <Route path="/reports-sales" component={ReportsSales} />
    <Route component={NotFound} />
</Switch>
);

export default Routes;
 