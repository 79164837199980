import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";
import axios from 'axios';

import $ from 'jquery';

const parameters = require('../config/Parameters.js');

const SettingsSystemParameters = () => {

    localStorage.setItem('mainMenu', "SettingsParameters");
    localStorage.setItem('whereAmI', "SettingsSystemParameters");

    const [gosterGostermeEditValue, setGosterGostermeEditValue] = useState('show');

	const [load, setLoad] = useState(true);

  // Check if there is JWT
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

    const [result, setResult] = useState('');
    const [listData, setListData] = useState([]);

    const [inputValue, setInputValue] = useState('');
    const [inputValueId, setInputValueId] = useState('');
    const [inputName, setInputName] = useState('');
    const [inputDescription, setInputDescription] = useState('');

    const [newValue, setNewValue] = useState('');

    const [authCheck, setAuthCheck] = useState('Authenticated');

    useEffect(() => {

    setLoad(false);
    
    if (localStorage.getItem('jwt')) {
      setJwt(localStorage.getItem('jwt'));
    } else {
        setAuthCheck('401');
    }

        getListData();

	}, []);
    

const handleEditValue = event => {
    
    event.preventDefault();
    setGosterGostermeEditValue('');
    $('body').attr('class','');
    $('.modal-backdrop').attr('class','');
    {/* <div class="modal-backdrop fade show"></div> */}

    axios({
        method: "put",
        url: parameters.ENDPOINT_URL+'/admin/systemsettings/parameters/edit-parameters',
        params: {
          id: inputValueId,
          value: inputValue
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: jwt
        }
      })
        .then(res => {
          getListData();
        })
        .catch(err => {
          setResult(
            <div className="alert alert-danger" role="alert">
              Temporary Error Occured
            </div>
          );
        });
}

const inputBoxSetter = (id, name, description, value) => {
    setInputValue(value);
    setInputValueId(id);
    setInputName(name);
    setInputDescription(description);
}

    const getListData = () => {
        axios
        .get(parameters.ENDPOINT_URL+'/admin/systemsettings/parameters/system-parameters', {
          headers: {
            accept: "application/json",
            "content-type": "application/x-www-form-urlencoded",
            token: jwt
          }
        })
        .then(res => {
          console.log(res.data.data.Params);
          setListData(res.data.data.Params);
        })
        .catch(err => {
        });
    }

  // Redirect if there is token
  if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
  }


    return (
        
<LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>Settings - End User Parameters</title>
            <meta name="description" content="Main Page" />
            </Helmet>

            <div id="wrapper">
                    <Menu />
                    
                    <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                    
                    <div class="container-fluid">
                        <h1 class="h3 mb-4 text-gray-800">Settings System Parameters</h1>
                    </div>

{/* <div className="row">
<div className="col-md-12 text-center">
<br/><br/><br/><br/>
<h1> <span style={{color: '#ff0000'}}> Temporary Error Occured! </span> </h1>
</div>
</div> */}

<div class="container-fluid">
<div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Parameter Detail Table</h6>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th>Parameter Name</th>
                      <th>Value</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                    {listData ? (
                            listData.map((listDataValue, index) => (
                                <tr> 
                                <td>{listDataValue.name}</td>
                                <td><input type="text" value={listDataValue.value} disabled /> 
                                <a href="#" data-toggle="modal" data-target="#EditValue" 
                                    onClick={ () => inputBoxSetter(
                                        listDataValue.id,
                                        listDataValue.name,
                                        listDataValue.description,
                                        listDataValue.value
                                        )}
                                > <i class="fas fa-fw fa-pen"></i> </a>
                                </td>
                                <td>{listDataValue.description}</td>
                                </tr>
                            ))) : (
                                <tr><td></td></tr>
                            )
                        }
                  <tfoot>
                  </tfoot>
                  <tbody>
                    
                  </tbody>
                </table>
              </div> 
              </div> 
              </div>  
              </div> 
                    </div>
                    <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Mahalome 2020</span>
                        </div>
                    </div>
                    </footer>
            </div>
            </div>

<div id="EditValue" className={`modal fade ${gosterGostermeEditValue}`} role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
            <h4 className="modal-title">Edit {inputName}</h4>
            </div>
            <div className="modal-body">
              <form className="user" onSubmit={handleEditValue}>
                <div className="form-group login-form">
                  <label for="exampleInputEmail1 form-text-style">
                    <span className="form-text-style">{inputDescription}</span>
                  </label>
                  <input
                    type="text"
                    value={inputValue}
                    onChange={e => {
                     setInputValue(e.target.value);
                    }}
                    className="form-control form-control-user"
                  />
                </div>
                <div className="form-group login-form">
                  <button
                    type="submit"
                    className="btn btn-primary fullwidth"
                  >
                    Update Value
                  </button>
                  &nbsp;
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>
 </LoadingOverlay>
 
    );
};

export default SettingsSystemParameters;
