import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";
import axios from 'axios';
import {Line} from 'react-chartjs-2';

import { For } from 'react-loops'

import $ from 'jquery';

const parameters = require('../config/Parameters.js');

const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'My First dataset',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40]
      }
    ]
  };

const UserManagementListUser = props => {

    localStorage.setItem('mainMenu', "UserManagementEndUsers");
    localStorage.setItem('whereAmI', "UserManagementListUser");

  // Check if there is JWT
     const [jwt, setJwt] = useState(localStorage.getItem('jwt'));
    
    const [load, setLoad] = useState(true);
    const [result, setResult] = useState('');
    const [userList, setUserList] = useState('');
    const [pageCount, setPageCount] = useState(1);
    const [pageNo, setPageNo] = useState(0);

    const [authCheck, setAuthCheck] = useState('Authenticated');
    const [orderBy, setOrderBy] = useState('');
    const [ascDesc, setAscDesc] = useState('desc');
 
    const [className, setClassName] = useState('desc');
    const [classRegisterDate, setClassRegisterDate] = useState('desc');
    const [classBalance, setClassBalance] = useState('desc');
    const [classStatus, setClassStatus] = useState('desc');

    const [bottomPaging, setBottomPaging] = useState('desc');


const changeOrderBy = myVariable => {

    if (ascDesc === "asc") {
      setAscDesc("desc");
    } else {
      setAscDesc("asc");
    }
    setOrderBy(myVariable);

    if (myVariable==="name") {
      setClassName(ascDesc);
    }
    if (myVariable==="registerDate") {
      setClassRegisterDate(ascDesc);
    }
    if (myVariable==="balance") {
      setClassBalance(ascDesc);
    }
    if (myVariable==="status") {
      setClassStatus(ascDesc);
    }
    setPageNo(0);

}


    useEffect(() => {

		setLoad(false);
    if (localStorage.getItem('jwt')) {
      setJwt(localStorage.getItem('jwt'));
    } else {
        setAuthCheck('401');
    }
 
        axios.get(
          parameters.ENDPOINT_URL+'/admin/usermanagement/endusers/listuser/'+pageNo+'/10/'+orderBy+'-'+ascDesc, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
        })
        .then(res => { 
            setUserList(res.data.data.Users);

            setPageCount(res.data.data.NumOfPages);
            let content = [];
        
            for (let i = 0; i < res.data.data.NumOfPages; i++) {
            content.push(<li className="paginate_button page-item "><a href="#" onClick={() => setPageNo(i)} aria-controls="dataTable" tabindex="0" className="page-link">{i+1}</a></li>);
            }
            setBottomPaging(content);
            console.log(res.data);

        })
        .catch(err => {
            setResult(
                <div class="alert alert-danger" role="alert">
                </div>
            );
        })        
	}, [pageNo, orderBy, ascDesc]);
  
  
// Redirect if there is token
if(authCheck!=="Authenticated" ){
  return <Redirect to="/login" />
}
  
    return (
        
<LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>User Management - List User</title>
            <meta name="description" content="Main Page" />
            </Helmet>

            <div id="wrapper">
                    <Menu />
                    
                    <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                    
                    <div class="container-fluid">
                        <h1 className="h3 mb-4 text-gray-800">User Management - List User</h1>

      {/* <div>
        <h2>Line Example</h2>
            <div className="row">                        
            <div className="col-xl-3 col-md-6 mb-4">
            <Line data={data} />
            </div>
        </div>

      </div> */}
 
      {/* <div className="row">
            <div className="col-md-12 text-center">
            <br/><br/><br/><br/>
              <h1> <span style={{color: '#ff0000'}}> Temporary Error Occured! </span> </h1>
            </div>
      </div> */}

        <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">End Users List Detail</h6>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered table-sortable" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                    <tr>

                      {/* <th>
                        <a href="#" 
                          onClick={() => setOrderBy('name')} 
                          aria-controls="dataTable">
                            Name Surname
                        </a>
                      </th> */}

                      <th className={className}>
                        <a href="#">MahalomeID</a>
                      </th>

                      <th className={className}>
                        <a href="#" 
                          onClick={() => changeOrderBy('name') } 
                          aria-controls="dataTable">
                            Fullname
                        </a>
                      </th>

                      <th className={classRegisterDate}>
                        <a href="#" 
                          onClick={() => changeOrderBy('registerDate')} 
                          aria-controls="dataTable">
                            Register Date
                        </a>
                      </th>

                      <th className={classBalance}>
                        <a href="#" 
                          onClick={() => changeOrderBy('balance')} 
                          aria-controls="dataTable">
                            Balance/Bonus/Must Spent
                        </a>
                      </th>
                      <th className={classStatus}>
                        <a href="#" 
                          onClick={() => changeOrderBy('status')} 
                          aria-controls="dataTable">
                            Status
                        </a>
                      </th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>Mahalome ID</th>
                      <th>Fullname</th>
                      {/* <th>Gender</th>
                      <th>Birthdate</th> */}
                      <th>Register Date</th>
                      <th>Balance/Bonus/Must Spent</th>
                      <th>Status</th>
                      <th>Edit</th> 
                    </tr>
                  </tfoot>
                  <tbody>
                    
                    {userList ? (
                            userList.map((userListValue, index) => (
                                <tr> 
                                <td>{userListValue.system_id}</td>
                                <td>{userListValue.name} {userListValue.surname}</td>
                                {/* <td>{userListValue.gender}</td>
                                <td>{userListValue.birthdate}</td> */}
                                <td>{userListValue.created_at}</td>
                                <td>{userListValue.balance} / {userListValue.bonus} / {userListValue.must_spent}</td>
                                <td>{userListValue.status}</td> 
                                <td><a href={`/user-management-user-edit/${userListValue.id}`}><i class="fas fa-fw fa-pen" aria-hidden="true"></i></a></td>
                                </tr>
                            ))) : (
                                <tr><td></td></tr>
                            )
                        }

                  </tbody>
                </table>
                
                <div className="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                  <ul className="pagination">
                     
                      {bottomPaging}

                      </ul></div>

              </div>
            </div>
          </div>
          
                    </div>
                    </div>

                    <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Mahalome 2020</span>
                        </div>
                    </div>
                    </footer>
 
            </div>
            </div>
 
 </LoadingOverlay>
 
    );
};

export default UserManagementListUser;
