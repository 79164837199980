    import React,{useEffect, useState} from "react";
    import { Link, Redirect } from "react-router-dom";
    import {Helmet} from "react-helmet";
    import LoadingOverlay from 'react-loading-overlay';
    import Navbar from "../_includes/navbar";
    import Menu from "../_includes/menu";
    import axios from 'axios';
    import {Line} from 'react-chartjs-2';
    import {useParams} from "react-router";

    import $ from 'jquery';
    
    const parameters = require('../config/Parameters.js');
    
    const UserManagementUserEdit = props => {
    
        localStorage.setItem('mainMenu', "UserManagementEndUsers");
        localStorage.setItem('whereAmI', "UserManagementListUser");
    
      let { userId } = useParams();

 

      // const [myAdminId, setMyAdminId] = useState(adminId);

      // Check if there is JWT
         const [jwt, setJwt] = useState(localStorage.getItem('jwt'));
        
        const [load, setLoad] = useState(true);
        const [result, setResult] = useState('');
        const [createResult, setCreateResult] = useState('');
        const [createResult2, setCreateResult2] = useState('');
        const [adminDetails, setAdminDetails] = useState('');
        const [pageCount, setPageCount] = useState(1);
        const [pageNo, setPageNo] = useState(0);
    
        const [authCheck, setAuthCheck] = useState('Authenticated');
    
        const [email, setEmail] = useState('');
        const [name, setName] = useState('');
        const [surname, setSurname] = useState('');
        const [status, setStatus] = useState('');
        const [password, setPassword] = useState('');
 
    const updateUserDetails = event => {
        
      event.preventDefault();
    
      // alert(userId + password + status + name + surname + email);
    
      axios({
        method: "put",
        url: parameters.ENDPOINT_URL+"/admin/usermanagement/admin/updateEndUserInfo",
        params: {
          id:userId,
          status:status,
          password:password,
          name:name,
          surname:surname,
          email:email
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: jwt
        }
      })
        .then(res => {
            console.log(res.data.resultDetail);
            setCreateResult2(<div className="alert alert-danger" role="alert">
          {res.data.resultDetail}
        </div>)
        })
        .catch(err => {
            setCreateResult2(
            <div className="alert alert-danger" role="alert">
              Temporary Error Occured
            </div>
          );
        });
 
    }

 
        useEffect(() => {
    
        setLoad(false);

        if (localStorage.getItem('jwt')) {
          setJwt(localStorage.getItem('jwt'));
        } else {
            setAuthCheck('401');
        }
     
          axios.get(
            parameters.ENDPOINT_URL+'/admin/getDetailsOfUser/'+userId, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
        })
        .then(res => { 
            console.log(res.data.resultDetail)
            
            setName(res.data.resultDetail.name);
            setSurname(res.data.resultDetail.surname);
            setEmail(res.data.resultDetail.email);
            setStatus(res.data.resultDetail.status);
        })
        .catch(err => {
            setResult(
                <div className="alert alert-danger" role="alert">
                </div>
            );
        }) 
      }, []);
      
      
    // Redirect if there is token
    if(authCheck!=="Authenticated" ){
      return <Redirect to="/login" />
    }
      
        return (
            
    <LoadingOverlay active={load} spinner> 
                <Helmet>
                <meta charSet="utf-8" />
                <title>User Management - Edit End User</title>
                <meta name="description" content="User Management - Edit End User" />
                </Helmet>
    

                <div id="wrapper">

                        <Menu /> 
                
                        <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Navbar />  
                        <div class="container-fluid">
                            <h1 className="h3 mb-4 text-gray-800">User Management - Edit User</h1>

              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">Edit End User</h6>
                  {createResult}
                </div>



                <div class="card-body">
                  <div class="table-responsive">
                  <form onSubmit={updateUserDetails}>
                    <table class="table table-bordered table-sortable" id="dataTable" width="100%" cellspacing="0">
                        <tr> 
                          <td>Name</td>
                          <td><input type="text" required value={name} onChange={(e) => { setName(e.target.value) }} className="form-control form-control-user" placeholder="Name" /></td>
                        </tr>
                        <tr> 
                          <td>Surname</td>
                          <td><input type="text" required value={surname} onChange={(e) => { setSurname(e.target.value) }} className="form-control form-control-user" placeholder="Surname" /></td>
                        </tr>
                        <tr> 
                          <td>Email</td>
                          <td><input type="email" required value={email} onChange={(e) => { setEmail(e.target.value) }} className="form-control form-control-user" placeholder="Email" /></td>
                        </tr>
                        <tr> 
                          <td>Status</td>
                          <td>
 
                          {(() => {
              if (status === 'ACTIVE'){
                  return (
                    <select onChange={(e) => { setStatus(e.target.value) }} ><option selected>Active</option><option>Passive</option><option>Blocked</option></select>
                  )
              } else if (status === 'PASSIVE') {
                  return (
                    <select onChange={(e) => { setStatus(e.target.value) }} ><option>Active</option><option selected>Passive</option><option>Blocked</option></select>
                  )
              } else {
                return (
                    <select onChange={(e) => { setStatus(e.target.value) }} ><option>Active</option><option>Passive</option><option selected>Blocked</option></select> 
                )
              } 
        
            })()}
                            
                          </td>
                        </tr>
                        <tr> 
                          <td>Change Password</td>
                          <td><input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} className="form-control form-control-user"/></td>
                        </tr>
                        <tr> 
                          <td colSpan="2"><input type="submit" className="btn btn-primary" /></td>
                        </tr> 
                      <tbody> 
                      </tbody>
                    </table> 
                </form>
                  </div>
                </div>

 </div></div></div></div></div>
     
                        <footer class="sticky-footer bg-white">
                        <div class="container my-auto">
                            <div class="copyright text-center my-auto">
                            <span>Copyright &copy; Mahalome 2020</span>
                            </div>
                        </div>
                        </footer>
     
             
     
     </LoadingOverlay>
     
        );
    };
    
    export default UserManagementUserEdit;
    