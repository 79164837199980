import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";
 
import axios from 'axios';

import {useParams} from "react-router";

const parameters = require('../config/Parameters.js');

const UserManagementTransactionLogging = () => {

    localStorage.setItem('mainMenu', "UserManagementSecurityOperations");
    localStorage.setItem('whereAmI', "UserManagementTransactionLogging");
    
    const [logList, setLogList] = useState('');

    let { uId } = useParams();

  // Check if there is JWT
  const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

	const [load, setLoad] = useState(true);
  const [systemLog, setSystemLog] = useState([]);
  
  const [authCheck, setAuthCheck] = useState('Authenticated');
  
  const [pageNo, setPageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [bottomPaging, setBottomPaging] = useState('');
  const [inputValue, setInputValue] = useState('');
   
  const searchLogs = event => {

    event.preventDefault();
    // alert(inputValue);
   
    axios({
      method: "post",
      url: parameters.ENDPOINT_URL+"/admin/usermanagement/endusers/transactionLogging",
      params: {
        inputValue: inputValue,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
      .then(res => {
          console.log(res.data.resultDetail);
          setSystemLog(res.data.resultDetail);
      })
      .catch(err => {
          
      });
  }




    useEffect(() => {
    setLoad(false);
    
    if (localStorage.getItem('jwt')) {
      setJwt(localStorage.getItem('jwt'));
    } else {
        setAuthCheck('401');
    }

        axios.get(
          parameters.ENDPOINT_URL+'/admin/usermanagement/securityoperations/transactionlogging/'+pageNo+'/'+uId, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
        })
        .then(res => { 
            console.log(res);
            setSystemLog(res.data.data.Records);
            setPageCount(res.data.data.NumOfPages);
            let content = [];
            for (let i = 0; i < res.data.data.NumOfPages; i++) {
            content.push(<li className="paginate_button page-item "><a href="#" onClick={() => setPageNo(i)} aria-controls="dataTable" tabindex="0" className="page-link">{i+1}</a></li>);
            }
            setBottomPaging(content);
        })
        .catch(err => {
        })        
 
	}, [pageNo]);
 
// Redirect if there is token
 if(authCheck!=="Authenticated" ){
  return <Redirect to="/login" />
}

    return (
        
<LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>User Management - Transaction Logging</title>
            <meta name="description" content="Main Page" />
            </Helmet>

            <div id="wrapper">
                    <Menu />
                    
                    <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                    
                    <div class="container-fluid">
                        <h1 class="h3 mb-4 text-gray-800">User Management - Transaction Logging</h1>
                    </div> 

          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Transaction Logs Detail</h6>
            </div>

            <div class="card-body">

      <form onSubmit={searchLogs}>
        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
        <tr>
        <td>User ID</td> 
        <td>
        <input type="text" value={inputValue} onChange={e => {setInputValue(e.target.value);}} className="form-control form-control-user"/>
        </td> 
        <td><input className="btn btn-primary" type="submit"/></td>
        </tr>
        </table>
      </form>

              <div class="table-responsive">
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th>User&nbsp;ID</th>
                      <th>Mahalome&nbsp;ID</th>
                      <th>User Type</th>
                      <th>Name</th>
                      <th>Channel</th>
                      <th>Page Name</th>
                      <th>Request</th>
                      <th>Response</th>
                      <th>Application</th>
                      <th>Date</th>
                    </tr>
                  </thead>

                  <tbody>

                  {systemLog ? (
                            systemLog.map((systemLogValue, index) => (
                                <tr> 
                                <td>{systemLogValue.user_id}</td>
                                <td>{systemLogValue.system_id}</td>
                                <td>{systemLogValue.user_type}</td>
                                <td>{systemLogValue.name} {systemLogValue.surname}</td>
                                <td>{systemLogValue.channel}</td>
                                <td>{systemLogValue.page_name}</td>
                                <td>{systemLogValue.request}</td>
                                <td>{systemLogValue.response}</td>
                                <td>{ systemLogValue.application ? (systemLogValue.application.replace('App\\Http\\Controllers\\User\\UserController', '././')) : 'x'  }</td>
                                <td>{systemLogValue.created_at}</td>

                                </tr>
                            ))) : (
                                <tr><td></td></tr>
                            )
                        }

                  </tbody>

                  <tfoot>
                    <tr>
                    <th>User ID</th>
                      <th>User Type</th>
                      <th>Name</th>
                      <th>Channel</th>
                      <th>Page Name</th>
                      <th>Request</th>
                      <th>Response</th>
                      <th>Application</th>
                      <th>Date</th>
                    </tr>
                  </tfoot>
                  <tbody>
                  </tbody>
                </table>

                  <div className="dataTables_paginate paging_simple_numbers" id="dataTable_paginate">
                  <ul className="pagination">
                  {bottomPaging}
                  </ul></div>

              </div>
            </div>
          </div>

{/* <div className="row">
<div className="col-md-12 text-center">
<br/><br/><br/><br/>
<h1> <span style={{color: '#ff0000'}}> Temporary Error Occured! </span> </h1>
</div>
</div> */}


                    </div>


                    <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Mahalome 2020</span>
                        </div>
                    </div>
                    </footer>
 
            </div>
            </div>
 
 </LoadingOverlay>
 
    );
};

export default UserManagementTransactionLogging;
