import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";

import axios from "axios";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const parameters = require('../config/Parameters.js');

const SystemSettingsStaticPagesMail = () => {
  localStorage.setItem("mainMenu", "SystemSettingsStaticPages");
  localStorage.setItem("whereAmI", "SystemSettingsStaticPagesMail");

  const [load, setLoad] = useState(true);
  // Check if there is JWT
  const [jwt, setJwt] = useState(localStorage.getItem("jwt"));
  const [authCheck, setAuthCheck] = useState("Authenticated");

  const [editorData, setEditorData] = useState('Hello');
  const [editorData1, setEditorData1] = useState('Hello');
  const [editorData2, setEditorData2] = useState('Hello');
  const [editorData3, setEditorData3] = useState('Hello');

  const [result, setResult] = useState('');

  const handleSubmit1 = event => {

    event.preventDefault();
 
        

  }

const getList = event => {
 
}

  useEffect(() => {

    setLoad(false);

    if (localStorage.getItem('jwt')) {
      setJwt(localStorage.getItem('jwt'));
    } else {
        setAuthCheck('401');
    }

      getList();

  }, []);

  // Redirect if there is token
  if (authCheck !== "Authenticated") {
    return <Redirect to="/login" />;
  }

  return (
    <LoadingOverlay active={load} spinner>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mail Content</title>
        <meta name="description" content="Main Page" />
      </Helmet>

      <div id="wrapper">
        <Menu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />


            <div class="container-fluid">
              <h1 class="h3 mb-4 text-gray-800">Welcome Mail</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                    <form className="user" onSubmit={handleSubmit1}>
                    <CKEditor
                    editor={ ClassicEditor }
                    data={editorData}
                    onInit={ editor => {
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setEditorData(editor.getData());
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
                    <div className="col-sm-12">&nbsp;</div>
                    <div className="col-sm-12"><input type="submit" className="btn btn-primary btn-block" value="Update" /> </div>
                    </form>
                    </div>
                </div>
            </div>  

<div className="spacer-60"></div>

            <div class="container-fluid">
              <h1 class="h3 mb-4 text-gray-800">Activation Mail</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                    <form className="user" onSubmit={handleSubmit1}>
                    <CKEditor
                    editor={ ClassicEditor }
                    data={editorData1}
                    onInit={ editor => {
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setEditorData1(editor.getData());
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
                    <div className="col-sm-12">&nbsp;</div>
                    <div className="col-sm-12"><input type="submit" className="btn btn-primary btn-block" value="Update" /> </div>
                    </form>
                    </div>
                </div>
            </div>  

            <div className="spacer-60"></div>

            <div class="container-fluid">
              <h1 class="h3 mb-4 text-gray-800">Chage Password Mail</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                    <form className="user" onSubmit={handleSubmit1}>
                    <CKEditor
                    editor={ ClassicEditor }
                    data={editorData2}
                    onInit={ editor => {
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setEditorData2(editor.getData());
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
                    <div className="col-sm-12">&nbsp;</div>
                    <div className="col-sm-12"><input type="submit" className="btn btn-primary btn-block" value="Update" /> </div>
                    </form>
                    </div>
                </div>
            </div>  

            <div className="spacer-60"></div>
            
            <div class="container-fluid">
              <h1 class="h3 mb-4 text-gray-800">Forgot Password Mail</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                    <form className="user" onSubmit={handleSubmit1}>
                    <CKEditor
                    editor={ ClassicEditor }
                    data={editorData3}
                    onInit={ editor => {
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setEditorData3(editor.getData());
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
                    <div className="col-sm-12">&nbsp;</div>
                    <div className="col-sm-12"><input type="submit" className="btn btn-primary btn-block" value="Update" /> </div>
                    </form>
                    </div>
                </div>
            </div>  




          </div>
          <footer class="sticky-footer bg-white">
            <div class="container my-auto">
              <div class="copyright text-center my-auto">
                <span>Copyright &copy; Mahalome 2020</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default SystemSettingsStaticPagesMail;
