import React, {useState,useEffect} from 'react';
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

const parameters = require('../config/Parameters.js');

const ForgotPassword = () => {

  const [load, setLoad] = useState(true);
  const [email, setEmail] = useState('');
  const [redirect, setRedirect] = useState(0);

  useEffect(() => {
    setLoad(false);
  }, []);


  const handleSubmit = event => {
    event.preventDefault();
    // set value
    setEmail(email);

    axios.post('https://api.gezihayallerim.com/login',{
        email: email,
    })
    .then(res => {
        setRedirect(1);
    })
    .catch(err => {
        
        console.log(err.message);
        setRedirect(1);
    })
}

if (redirect===0) {
      return (
        <LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>Login</title>
            <meta name="description" content="Login" />
            </Helmet>
    <div className="container">
    <div className="row justify-content-center">
    <div className="col-xl-10 col-lg-12 col-md-9">
    <div className="card o-hidden border-0 shadow-lg my-5">
      <div className="card-body p-0">
        <div className="row">
          <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
          <div className="col-lg-6">
            <div className="p-5">
              <div className="text-center">
                <h1 className="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                <p className="mb-4">We get it, stuff happens. Just enter your email address below and we'll send you a link to reset your password!</p>
              </div>
              <form className="user" onSubmit={handleSubmit}>
                <div className="form-group"> 
                  <input type="email" value={email} required onChange={(e) => { setEmail(e.target.value) }} className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." />
                </div>
                <input type="submit" value="Reset Password" className="btn btn-primary btn-user btn-block" />
              </form>
              <hr/>
              <div className="text-center">
                <a className="small" href="register">Create an Account!</a>
              </div>
              <div className="text-center">
                <a className="small" href="login">Already have an account? Login!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </LoadingOverlay>

    );
} else {
  return (
    <LoadingOverlay active={load} spinner> 
        <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
        <meta name="description" content="Login" />
        </Helmet>
<div className="container">
<div className="row justify-content-center">
<div className="col-xl-10 col-lg-12 col-md-9">
<div className="card o-hidden border-0 shadow-lg my-5">
  <div className="card-body p-0">
    <div className="row">
      <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
      <div className="col-lg-6">
        <div className="p-5">
          <div className="text-center">
            <h1 className="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
            <p className="mb-4">We get it, stuff happens. Just enter your email address below and we'll send you a link to reset your password!</p>
          </div>

          <div className="text-center">
          Talebiniz alınmıştır babayiğit.
          </div>

          <hr/>
          <div className="text-center">
            <a className="small" href="register">Create an Account!</a>
          </div>
          <div className="text-center">
            <a className="small" href="login">Already have an account? Login!</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
</LoadingOverlay>

);
}



}

export default ForgotPassword;


