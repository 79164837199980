import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";
 
import axios from 'axios';
import $ from 'jquery';

const parameters = require('../config/Parameters.js');

const SystemManagementLoadWithdraw = () => {

    localStorage.setItem('mainMenu', "SystemManagementFundTransactions");
    localStorage.setItem('whereAmI', "SystemManagementLoadWithdraw");
    
    const [load, setLoad] = useState(true);
    // Check if there is JWT
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));
    const [systemLog, setSystemLog] = useState([]);

    const [authCheck, setAuthCheck] = useState('Authenticated');
    const [moneyToWithdraw, setMoneyToWithdraw] = useState('');
    const [moneyToLoad, setMoneyToLoad] = useState('');
    const [loadBankId, setLoadBankId] = useState('');
    const [withdrawBankId, setWithdrawBankId] = useState('');
    const [result1, setResult1] = useState('');
    const [result2, setResult2] = useState('');

    const [result, setResult] = useState("");

    const [bankList, setBankList] = useState([]);
    const [cardList, setCardList] = useState([]);
  
    const [newCardHolderName, setNewCardHolderName] = useState("");
    const [newCardNumber, setNewCardNumber] = useState("");
    const [newCvcNumber, setNewCvcNumber] = useState("");
    const [newExpireDate, setNewExpireDate] = useState("");
  
    const [newIban, setNewIban] = useState("");
    const [newBankName, setNewBankName] = useState("");
  
    const [creditCardIndexNumber, setCreditCardIndexNumber] = useState(0);
    const [bankAccountIndexNumber, setBankAccountIndexNumber] = useState(0);
  
    const [gosterGostermeBank, setGosterGostermeBank] = useState('show');
    const [gosterGostermeCard, setGosterGostermeCard] = useState('show');
    const [gosterGostermeMyModal1, setGosterGostermeMyModal1] = useState('show');
    const [gosterGostermeMyModal2, setGosterGostermeMyModal2] = useState('show');




    const [adminWalletFinalStatus, setAdminWalletFinalStatus] = useState();



    const getAdminWalletFinalStatus = event => {
      axios.get(
        parameters.ENDPOINT_URL+'/admin/get-admin-wallet-final-status', {
          headers: {
              'accept': 'application/json',
              'content-type': 'application/x-www-form-urlencoded',
              'token': jwt
          }
      })
      .then(res => { 
          console.log(res.data.data[0].wallet_money);
          setAdminWalletFinalStatus(res.data.data[0].wallet_money);
      })
      .catch(err => {
        console.log(err);
      })
       
    }




    useEffect(() => {

      if (localStorage.getItem('jwt')) {
        setJwt(localStorage.getItem('jwt'));
      } else {
          setAuthCheck('401');
      }
 
    setLoad(false);
    getAdminWalletFinalStatus();
    getList();

	}, []);
  

  const handleSubmitDeleteBankAccount = event => {
  
    setGosterGostermeBank('');
    $('body').attr('class','');
    $('.modal-backdrop').attr('class','');
    $('#DeleteBankAccount').attr("style", "display:none");
    event.preventDefault();
 
    axios({
      method: "delete",
      url: parameters.ENDPOINT_URL+"/admin/bank",
      params: {
        id: bankAccountIndexNumber
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
      .then(res => {
        setResult(
          <div className="alert alert-danger" role="alert">Bank Account Deleted</div>
        );
        getList();
      })
      .catch(err => {
        setResult(
          <div className="alert alert-danger" role="alert">Temporary Error Occured</div>
        );
      });
 
  }

const handleSubmit1 = event => {

  event.preventDefault();
  setGosterGostermeMyModal1('');
  $('body').attr('class','');
  $('.modal-backdrop').attr('class','');

  axios({
    method: "post",
    url: parameters.ENDPOINT_URL+"/admin/bank",
    params: {
      iban: newIban,
      name: newBankName,
      bank_id: 22
    },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      token: jwt
    }
  })
    .then(res => {
      setResult(
        <div className="alert alert-danger" role="alert">
          Bank is added!
        </div>
      );
      getList();

    })
    .catch(err => {
      setResult(
        <div className="alert alert-danger" role="alert">
          Temporary Error Occured
        </div>
      );
    });

}

  const getList = event => {
    // Banks
    axios
    .get(parameters.ENDPOINT_URL+"/admin/bank", {
      headers: {
        accept: "application/json",
        "content-type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
    .then(res => {
      setBankList(res.data.data.Banks);
      setResult('');
    })
    .catch(err => {
      setResult(
        <div className="alert alert-danger" role="alert">
          Temporary Error Occured
        </div>
      );
    });
     
      }

const handleLoadSubmit = event => {
    event.preventDefault();

    axios({
        method: "post",
        url: parameters.ENDPOINT_URL+"/admin/send-admin-to-system",
        params: {
            amount:moneyToLoad
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: jwt
        }
      }).then(res => {
        console.log( JSON.stringify(res) )
        setResult1(
          <div>
          <div>&nbsp;</div>
          <div class="alert alert-info" role="alert">
              Transfer is successful.
          </div>
        </div>
        );
        getAdminWalletFinalStatus();
    })
    .catch(err => {
      console.log( JSON.stringify(err) )
      setResult1(
      <div>
      <div>&nbsp;</div>
      <div class="alert alert-danger" role="alert">
          Transfer is failed.
      </div>
    </div>
      )
    });

}

const handleWithdrawSubmit = event => {

    event.preventDefault();
    
    axios({
        method: "post",
        url: parameters.ENDPOINT_URL+"/admin/send-system-to-admin",
        params: {
          amount:moneyToWithdraw
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: jwt
        }
      }).then(res => {
        console.log( JSON.stringify(res) )
        setResult2(
          <div>
          <div>&nbsp;</div>
          <div class="alert alert-info" role="alert">
              Transfer is successful.
          </div>
        </div>
        );
        getAdminWalletFinalStatus();
    })
    .catch(err => {
      console.log( JSON.stringify(err) )
      setResult2(
        <div>
        <div>&nbsp;</div>
        <div class="alert alert-danger" role="alert">
            Transfer is failed.
        </div>
      </div>
      )
    });
} 


  // Redirect if there is token
  if(authCheck!=="Authenticated" ){
    return <Redirect to="/login" />
  }

    return (
        
<LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>System Management - Debit Credit</title>
            <meta name="description" content="Main Page" />
            </Helmet>

            <div id="wrapper">
                    <Menu />
                    
                    <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
             
        <div className="spacer-25"></div>
  







        <div class="container-fluid"> 
            <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Current Wallet of the System</h6>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <form className="user" onSubmit={handleLoadSubmit}>
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="50%">Wallet Amount</td>
                      <td width="50%">
                      <h4>$ {adminWalletFinalStatus}</h4>
                      </td>
                    </tr> 
                  </tbody>
                </table>
                </form>
            </div></div></div>

            <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Withdraw Money from System to Bank</h6>
            </div>
            <div class="card-body">
              <div class="table-responsive">
              
              <form className="user" onSubmit={handleWithdrawSubmit}>
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="50%">Amount of Money</td>
                      <td width="50%">
                      <input type="text" value={moneyToWithdraw} required onChange={(e) => { setMoneyToWithdraw(e.target.value) }} className="form-control" maxLength="7"/>  
                      </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                        <button type="submit" className="btn btn-primary full-width">Transfer</button>
                        </td>
                    </tr>
                  </tbody>
                </table>
               </form>
               {result2}
            </div></div></div>
          </div>





  
          <div class="container-fluid"> 
            <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Loading Money from Bank to System</h6>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <form className="user" onSubmit={handleLoadSubmit}>
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="50%">Amount of Money</td>
                      <td width="50%">
                      <input type="text" value={moneyToLoad} required onChange={(e) => { setMoneyToLoad(e.target.value) }} className="form-control" maxLength="7"/> 
                      </td>
                    </tr>
                    <tr><td colSpan="2"><button type="submit" className="btn btn-primary full-width">Transfer</button></td></tr>
                  </tbody>
                </table>
                </form>
                {result1}
            </div></div></div>
 
          </div>

 
                    </div>
                    <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Mahalome 2020</span>
                        </div>
                    </div>
                    </footer>
            </div>
            </div>
 



        <div id="myModal1" className={`modal fade ${gosterGostermeMyModal1}`} role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">New Bank Account</h4>
            </div>
            <div className="modal-body">
              <form className="user" onSubmit={handleSubmit1}>
                <div className="form-group login-form">
                  <label for="exampleInputEmail1 form-text-style">
                    <span className="form-text-style">Bank Name</span>
                  </label>
                  <input
                    type="text"
                    value={newBankName}
                    required
                    onChange={e => {
                      setNewBankName(e.target.value);
                    }}
                    className="form-control form-control-user"
                    placeholder="Bank Name"
                  />
                </div>
                <div className="form-group login-form">
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">Iban</span>
                  </label>
                  <input
                    type="text"
                    value={newIban}
                    required
                    onChange={e => {
                      setNewIban(e.target.value);
                    }}
                    className="form-control form-control-user"
                    placeholder="Iban"
                  />
                </div>
                <div className="form-group login-form">
                  <button
                    type="submit"
                    className="btn btn-primary fullwidth"
                  >
                    Save Bank
                  </button>
                  <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>



      <div id="DeleteBankAccount" className={`modal fade ${gosterGostermeBank}`} role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Delete Bank Account</h4>
            </div>
            <div className="modal-body">
              <form className="user" onSubmit={handleSubmitDeleteBankAccount}>

                <div className="form-group login-form">
                  <label for="exampleInputEmail1 form-text-style">
                    <span className="form-text-style">Bank Account  {bankAccountIndexNumber}</span>
                  </label> 
                </div>

                <div className="form-group login-form">
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">Deleting Bank Account</span>
                  </label>
                   
                  <label for="exampleInputPassword1">
                    <span className="form-text-style">Are you sure ?</span>
                  </label>
                </div>

                <div className="form-group login-form">
                <button
                    type="submit"
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                  <button
                    data-dismiss="modal"
                    className="btn btn-info float-right"
                  >
                    Cancel
                  </button>

                </div>
              </form>
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>



 </LoadingOverlay>
 
    );
};

export default SystemManagementLoadWithdraw;
