import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";
 
import axios from 'axios';

import $ from 'jquery';

const parameters = require('../config/Parameters.js');

const MarketingListCampaign = () => {

    localStorage.setItem('mainMenu', "MarketingCampaigns");
    localStorage.setItem('whereAmI', "MarketingListCampaign");
	const [load, setLoad] = useState(true);

    const [authCheck, setAuthCheck] = useState('Authenticated');

    // Check if there is JWT
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));
    
    useEffect(() => {

        axios.get(
            parameters.ENDPOINT_URL+'/admin/auth/check-session', {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded',
                'token': jwt
            }
            })
            .then(res => { 
            setAuthCheck(res.data.result);
            console.log("**"+res.data.result);
            })
            .catch(err => {
              setAuthCheck('401');
            }) 

		setLoad(false);
		
	}, []);
	
    return (
        
<LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>Campaigns</title>
            <meta name="description" content="Main Page" />
            </Helmet>

            <div id="wrapper">
                    <Menu />
                    
                    <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                    
                    <div class="container-fluid">
                        <h1 class="h3 mb-4 text-gray-800">Marketing - Campaigns</h1>
                    </div>
                        
        


            <div class="container-fluid">
            <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Campaign Rules</h6>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th>Rule No</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Dates Available</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                        <td>1</td>
                        <td>20$ bonus is loaded when first time loaded .....</td>
                        <td><b>Enabled</b> / Disabled</td>
                        <td>2020/02/19 - 2020/02/27 &nbsp;
                            <a href="#"><i class="fa fa-calendar" aria-hidden="true"></i></a> 
                        </td>
                    </tr>
                  </tbody>
                  <tfoot>
                  </tfoot>
                </table>
              </div> 
              </div> 
              </div>  
              </div> 









                    </div>

                    <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Mahalome 2020</span>
                        </div>
                    </div>
                    </footer>
 
            </div>
            </div>
 
 </LoadingOverlay>
 
    );
};

export default MarketingListCampaign;
