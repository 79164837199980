import React,{useEffect, useState} from "react";
import { Link, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import LoadingOverlay from 'react-loading-overlay';
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";
 
import axios from 'axios';

import {useParams} from "react-router";

const parameters = require('../config/Parameters.js');

const UserManagementDebitCredit = () => {

    localStorage.setItem('mainMenu', "MainDashboard");
    localStorage.setItem('whereAmI', "MainDashboard");
 
  // Check if there is JWT
  const [jwt, setJwt] = useState(localStorage.getItem('jwt'));

	const [load, setLoad] = useState(true);
  const [systemLog, setSystemLog] = useState([]);

  const [authCheck, setAuthCheck] = useState('Authenticated');

  const [pageNo, setPageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [bottomPaging, setBottomPaging] = useState('');
  const [inputValue, setInputValue] = useState('');

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [current_password, set_current_password] = useState('');
  const [new_password1, set_new_password1] = useState('');
  const [new_password2, set_new_password2] = useState('');
 
  const [createResult, setCreateResult] = useState('');
  const [updateAdmin, setUpdateAdmin] = useState('');
  const [createResult2, setCreateResult2] = useState('');
  const [updateAdminPassword, setUpdateAdminPassword] = useState('');
 



  const updateDetails2 = event => {
        
    event.preventDefault();
    
    axios({
      method: "put",
      url: parameters.ENDPOINT_URL+"/admin/update-admin-password",
      params: {
        current_password:current_password,
        new_password1:new_password1,
        new_password2:new_password2,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
      .then(res => {
          console.log(res.data.resultDetail);
          setCreateResult2(<div className="alert alert-danger" role="alert">{res.data.resultDetail}</div>)
      })
      .catch(err => {
          setCreateResult2(
          <div className="alert alert-danger" role="alert">
            Temporary Error Occured
          </div>
        );
      });
  
       
  }

  const updateDetails1 = event => {
        
    event.preventDefault();
    
    axios({
      method: "put",
      url: parameters.ENDPOINT_URL+"/admin/update-admin-profile-details",
      params: {
        password:password,
        name:name,
        surname:surname,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
      .then(res => {
          console.log(res.data.resultDetail);
          setCreateResult(<div className="alert alert-danger" role="alert">{res.data.resultDetail}</div>)
      })
      .catch(err => {
        setCreateResult(
          <div className="alert alert-danger" role="alert">
            Temporary Error Occured
          </div>
        );
      });
  }






    useEffect(() => {

      setLoad(false);

      if (localStorage.getItem('jwt')) {
        setJwt(localStorage.getItem('jwt'));
      } else {
          setAuthCheck('401');
      }
 
        axios.get(
          parameters.ENDPOINT_URL+'/admin/get-admin-profile-details', {
          headers: {
              'accept': 'application/json',
              'content-type': 'application/x-www-form-urlencoded',
              'token': jwt
          }
      })
      .then(res => { 
          console.log( JSON.stringify(res.data.data.ProfileDetails) );
          setName(res.data.data.ProfileDetails.name);
          setSurname(res.data.data.ProfileDetails.surname);
          setEmail(res.data.data.ProfileDetails.email);
      })
      .catch(err => {
      })   
	}, []);
  
  
  
// Redirect if there is token
if(authCheck!=="Authenticated" ){
  return <Redirect to="/login" />
}
  
    return (
        
<LoadingOverlay active={load} spinner> 
            <Helmet>
            <meta charSet="utf-8" />
            <title>Profile</title>
            <meta name="description" content="Main Page" />
            </Helmet>

            <div id="wrapper">
                    <Menu />
                    
                    <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                    
                    <div class="container-fluid">
                        <h1 class="h3 mb-4 text-gray-800">Profile</h1>
                    </div>



            <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Profile</h6>
            </div>

            <div class="card-body">

 



            <div class="container-fluid">
                            <h1 className="h3 mb-4 text-gray-800">Profile</h1> 
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">Edit Profile Details</h6>
                  {createResult}
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                  <form className="user" onSubmit={updateDetails1}>
                    <table class="table table-bordered table-sortable" id="dataTable" width="100%" cellspacing="0">
                        <tr> 
                          <td>Name</td>
                          <td><input type="text" required value={name} onChange={(e) => { setName(e.target.value) }} className="form-control form-control-user" placeholder="Name" /></td>
                        </tr>
                        <tr> 
                          <td>Surname</td>
                          <td><input type="text" required value={surname} onChange={(e) => { setSurname(e.target.value) }} className="form-control form-control-user" placeholder="Surname" /></td>
                        </tr>
                        <tr> 
                          <td>Email</td>
                          <td><input type="email" required value={email} onChange={(e) => { setEmail(e.target.value) }} className="form-control form-control-user" placeholder="Email" disabled /></td>
                        </tr>
                        <tr> 
                          <td>Current Password</td>
                          <td><input type="password" required value={password} onChange={(e) => { setPassword(e.target.value) }} className="form-control form-control-user"/></td>
                        </tr>
                        <tr> 
                          <td colSpan="2"><input type="submit" className="btn btn-primary" /></td>
                        </tr> 
                      <tbody> 
                      </tbody>
                    </table> 
                    </form>
                  </div>
                </div>
                <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">Change Power User Password</h6>
                  {createResult2}
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                  <form className="user" onSubmit={updateDetails2}>
                    <table class="table table-bordered table-sortable" id="dataTable" width="100%" cellspacing="0">
 
                        <tr> 
                          <td>New Password</td>
                          <td><input type="password" required value={new_password1} onChange={(e) => { set_new_password1(e.target.value) }} className="form-control form-control-user"/></td>
                        </tr>

                        <tr> 
                          <td>New Password Again</td>
                          <td><input type="password" required value={new_password2} onChange={(e) => { set_new_password2(e.target.value) }} className="form-control form-control-user"/></td>
                        </tr>

                        <tr> 
                          <td>Current Password</td>
                          <td><input type="password" required value={current_password} onChange={(e) => { set_current_password(e.target.value) }} className="form-control form-control-user" /></td>
                        </tr> 

                        <tr> 
                          <td colSpan="2"><input type="submit" className="btn btn-primary" /></td>
                        </tr> 
                      <tbody> 
                      </tbody>
                    </table> 
                    </form>
                  </div>
                </div>
                </div>
              </div>
                        </div>





             
            </div>
          </div>

                    </div>

                    <footer class="sticky-footer bg-white">
                    <div class="container my-auto">
                        <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Mahalome 2020</span>
                        </div>
                    </div>
                    </footer>
 
            </div>
            </div>
 
 </LoadingOverlay>
 
    );
};

export default UserManagementDebitCredit;
