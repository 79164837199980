import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import Navbar from "../_includes/navbar";
import Menu from "../_includes/menu";

import axios from "axios";

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const parameters = require('../config/Parameters.js');

const SystemSettingsStaticHelp = () => {
    localStorage.setItem('mainMenu', "SystemSettingsStaticPages");
    localStorage.setItem('whereAmI', "SystemSettingsStaticPagesHelp");

  const [load, setLoad] = useState(true);
  // Check if there is JWT
  const [jwt, setJwt] = useState(localStorage.getItem("jwt"));
  const [authCheck, setAuthCheck] = useState("Authenticated");

  const [editorData, setEditorData] = useState('Hello');
  const [result, setResult] = useState('');

  const handleSubmit1 = event => {

    event.preventDefault();

    axios({
        method: "put",
        url: parameters.ENDPOINT_URL+"/admin/adminmanagement/staticpage/help",
        params: {
            myContent: editorData,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: jwt
        }
      })
        .then(res => {
            getList();
        })
        .catch(err => {
          setResult(
            <div className="alert alert-danger" role="alert">
              Temporary Error Occured
            </div>
          );
          getList();
        });
  }

const getList = event => {

    axios
    .get(parameters.ENDPOINT_URL+"/admin/adminmanagement/staticpage/help", {
      headers: {
        accept: "application/json",
        "content-type": "application/x-www-form-urlencoded",
        token: jwt
      }
    })
    .then(res => {
      setEditorData(res.data.resultDetail)
      console.log(res);
    })
    .catch(err => {
    });

}

  useEffect(() => {

    setLoad(false);

    if (localStorage.getItem('jwt')) {
      setJwt(localStorage.getItem('jwt'));
    } else {
        setAuthCheck('401');
    }

      getList();

  }, []);

  // Redirect if there is token
  if (authCheck !== "Authenticated") {
    return <Redirect to="/login" />;
  }

  return (
    <LoadingOverlay active={load} spinner>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Help</title>
        <meta name="description" content="Help" />
      </Helmet>

      <div id="wrapper">
        <Menu />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />

            <div class="container-fluid">
              <h1 class="h3 mb-4 text-gray-800">Help</h1>
            </div>


            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                    <form className="user" onSubmit={handleSubmit1}>
                    
                    <CKEditor
                    editor={ ClassicEditor }
                    data={editorData}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setEditorData(editor.getData());
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
                    

                    <div className="col-sm-12">&nbsp;</div>

                    <div className="col-sm-12"><input type="submit" className="btn btn-primary btn-block" value="Update" /> </div>
                    </form>
                    </div>
                   

                </div>
            </div>  
          </div>

          <footer class="sticky-footer bg-white">
            <div class="container my-auto">
              <div class="copyright text-center my-auto">
                <span>Copyright &copy; Mahalome 2020</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default SystemSettingsStaticHelp;
 